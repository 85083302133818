//import 'jquery-validation';
import Swiper, { Navigation, Thumbs } from "swiper";
import lozad from "lozad";
import fancybox from "@fancyapps/fancybox";
import inputmask from "inputmask";
import ymaps from "ymaps";

import noUiSlider from "nouislider";
const alertify = require("alertifyjs/build/alertify.js");
Swiper.use([Navigation]);
Swiper.use([Thumbs]);

jQuery(function ($) {
  jQuery(($) => {
    if ($(window).width() > 768) {
      $(".preloader").fadeOut(400, function () {
        // $('body').removeClass('opened');
        $(
          ".header, .first, .first__content, .first__labels, .first__menu, .first__bg, .first__labels__item"
        ).addClass("loaded");
      });
    } else {
      $(".preloader").fadeOut(400, function () {
        // $('body').removeClass('opened');
        // $('.first').addClass('loaded');
      });
    }
  });

  $(window).resize(function () {
    if ($(window).width() > 768) {
      $(".preloader").fadeOut(400, function () {
        // $('body').removeClass('opened');
        $(
          ".header, .first, .first__content, .first__labels, .first__menu, .first__bg, .first__labels__item"
        ).addClass("loaded");
      });
    } else {
      $(".preloader").fadeOut(400, function () {
        // $('body').removeClass('opened');
        // $('.first').addClass('loaded');
      });
    }
  });

  function fixedMenu() {
    $(window).on("scroll", function () {
      let st = $(this).scrollTop();
      let navScroll = $(".nav").outerHeight();
      let target = $(".fixed-menu");
      if (st > navScroll) {
        target.addClass("fixed");
      } else {
        target.removeClass("fixed");
      }
    });
  }
  fixedMenu();
  $(document).on("click", "[data-modal]", function () {
    let target = "." + $(this).attr("data-modal");
    $.fancybox.open($(target), {
      modal: false,
      hideScrollbar: true,
      //smallBtn: false,
      clickOutside: "close",
    });
    return false;
  });
  $(document).on("click", ".flats__slider__item__buttons__item", function () {
    $(".modal_flat .modal__form__title").text(
      "Оставить заявку на квартиру тип " + $(this).attr("data-type")
    );
    $(".modal_flat input[name=email_title]").val(
      "Оставить заявку на квартиру тип " + $(this).attr("data-type")
    );
  });
  $("input[name=name]").on("keyup", function () {
    this.value = this.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
  });
  function menu() {
    $(document).on("click", ".header__burger", function () {
      // $('body').addClass('opened');
      $(".menu").addClass("active");
    });
    $(document).on("click", ".menu__close", function () {
      // $('body').removeClass('opened');
      $(".menu").removeClass("active");
    });
  }
  menu();
  Inputmask({
    mask: "+7 (e99) 999-99-99",
    showMaskOnHover: false,
    definitions: {
      e: {
        validator: "[0-7,9]",
      },
    },
  }).mask(document.querySelectorAll("input[name=phone]"));
  function parallax() {
    let layer1, layer2, layer3, layer4;
    layer1 = document.querySelector(".layer-1");
    layer2 = document.querySelector(".layer-2");
    layer3 = document.querySelector(".layer-3");
    layer4 = document.querySelector(".layer-4");
    addEventListener("mousemove", (e) => {
      let valueX = e.pageX - document.documentElement.clientWidth / 2;
      let valueY = e.pageY - document.documentElement.clientHeight / 2;
      layer1.style.transform =
        "translateX(" +
        (valueX / 48 - layer1.offsetWidth / 50) +
        "px) translateY(" +
        (valueY / 48 - layer1.offsetWidth / 20) +
        "px)";
      layer2.style.transform =
        "translateX(" +
        (valueX / 64 - layer1.offsetWidth / 50) +
        "px) translateY(" +
        (valueY / 64 - layer1.offsetWidth / 20) +
        "px)";
      layer3.style.transform =
        "translateX(" +
        (valueX / 256 - layer1.offsetWidth / 50) +
        "px) translateY(" +
        (valueY / 256 - layer1.offsetWidth / 20) +
        "px)";
      layer4.style.transform =
        "translateX(" +
        (-valueX / 96 - layer1.offsetWidth / 50) +
        "px) translateY(" +
        (-valueY / 96 - layer1.offsetWidth / 20) +
        "px)";
    });
  }
  if (window.outerWidth >= 970 && document.querySelector(".layer-1") != null) {
    parallax();
  }
  function modal() {
    $(".modal__close").click(function () {
      $.fancybox.close();
      return false;
    });
  }
  modal();
  function scrollTo() {
    $("[data-scroll]").click(function () {
      let target = "." + $(this).data("scroll");
      $(".menu").removeClass("active");
      $("html, body").animate(
        {
          scrollTop: $(target).offset().top - 100,
        },
        300
      );
      return false;
    });
  }
  scrollTo();
  function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) {
      return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }
    if (n1 == 1) {
      return text_forms[0];
    }
    return text_forms[2];
  }
  function setMeta() {
    $("<input>", {
      type: "hidden",
      name: "location",
      value: document.location.href,
    }).appendTo("form");
    ym(74710348, "getClientID", function (clientID) {
      $("<input>", {
        type: "hidden",
        name: "clint_ya_id",
        value: clientID,
      }).appendTo("form");
    });
  }
  setMeta();
  let building;
  function buildingSlider() {
    building = new Swiper(".building__tab.active .building__slider__inner", {
      //allowTouchMove: false,
      //touchRation: 0,
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: ".building__tab.active .building__slider__control:nth-child(1)",
        prevEl: ".building__tab.active .building__slider__control:nth-child(2)",
      },
      pagination: {
        el: ".building__tab.active .building__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      loop:
        $(
          ".building__tab.active .building__slider__inner .building__slider__item"
        ).length > 2
          ? true
          : false,
      breakpoints: {
        970: {
          slidesPerView: 3,
        },
        600: {
          slidesPerView: 2,
        },
      },
    });
  }
  buildingSlider();
  function buildingSelect() {
    $(document).on("click", ".building__head__select", function () {
      $(".building__head__select__arrow").addClass("active");
      $(".building__head__select__dropdown").addClass("active");
    });
    $(".building__head__select__dropdown").on(
      "click",
      ".building__head__select__dropdown__item:not(.active)",
      function () {
        $(".building__head__select__current").text($(this).text());
        $(this).addClass("active").siblings().removeClass("active");
        $(".building__tabs")
          .find(".building__tab")
          .removeClass("active")
          .eq($(this).index())
          .addClass("active");
        building.destroy();
        buildingSlider();
        $(".building__head__select__arrow").removeClass("active");
        $(".building__head__select__dropdown").removeClass("active");
        return false;
      }
    );
  }
  buildingSelect();
  function otherHide() {
    $(document).on("click", ".other__button", function () {
      $(this).toggleClass("active");
      $(".other__list").slideToggle();
    });
  }
  otherHide();
  function commercialTabs() {
    $(".commercial__menu").on(
      "click",
      ".commercial__menu__item:not(.active)",
      function () {
        $(this).addClass("active").siblings().removeClass("active");
        $(".commercial__tabs")
          .find(".commercial__tab")
          .removeClass("active")
          .eq($(this).index())
          .addClass("active");
        return false;
      }
    );
  }
  commercialTabs();
  function calcValues() {
    var current_price_input = $(".calculator__form__field-price input");
    var current_price = parseInt(current_price_input.val().replace(/\s/g, ""));
    var current_first_input = $(".calculator__form__field-first1 input");
    var current_first = parseInt(current_first_input.val().replace(/\s/g, ""));
    var current_years_input = $(".calculator__form__field-years input");
    var current_years = parseInt(current_years_input.val().replace(/\s/g, ""));

    // var calc_month_rate = 2.55 / 12 / 100;
    // var calc_base_rate = (parseInt(1 + calc_month_rate) / (current_years * 12));
    // var calc_month_pay = parseInt((current_price - current_first) * calc_base_rate * 2.55 / (2.55 - 1));
    // var calc_over = calc_month_pay * (current_years * 12) - (current_price - current_first);
    var calc_months = current_years * 12;
    var calc_month_rate = 2.55 / 12 / 100;
    var calc_base_rate = Math.pow(1 + calc_month_rate, calc_months);
    var calc_month_pay =
      ((current_price - current_first) * calc_month_rate * calc_base_rate) /
      (calc_base_rate - 1);
    var calc_over =
      calc_month_pay * calc_months - (current_price - current_first);

    var calc_month_rate_big = 7 / 12 / 100;
    var calc_base_rate_big = Math.pow(1 + calc_month_rate_big, calc_months);
    var calc_month_pay_big =
      ((current_price - current_first) *
        calc_month_rate_big *
        calc_base_rate_big) /
      (calc_base_rate_big - 1);
    var calc_over_big =
      calc_month_pay_big * calc_months - (current_price - current_first);
    $(".calculator__result__list__value-current").text(
      parseInt(calc_month_pay).toLocaleString() + " ₽"
    );
    $(".calculator__result__list__value-over").text(
      parseInt(calc_over).toLocaleString() + " ₽"
    );
    $(".calculator__result__list__compare-current").text(
      "Дешевле на " +
        parseInt(calc_month_pay_big - calc_month_pay).toLocaleString() +
        " ₽"
    );
    $(".calculator__result__list__compare-over").text(
      "Меньше на " + parseInt(calc_over_big - calc_over).toLocaleString() + " ₽"
    );
    $(".calculator__result__bottom").text(
      "Экономия " + parseInt(calc_over_big - calc_over).toLocaleString() + " ₽"
    );
  }
  function calculator() {
    var price = document.querySelector(".slider.price");
    var first = document.querySelector(".slider.first1");
    var years = document.querySelector(".slider.years");
    var current_price_input = $(".calculator__form__field-price input");
    var current_price = parseInt(current_price_input.val().replace(/\s/g, ""));
    var min = $(".slider.price").attr("data-min");
    var max = $(".slider.price").attr("data-max");
    var rate = $(".slider.price").attr("data-rate");
    var time = $(".slider.price").attr("data-time");
    noUiSlider.create(first, {
      start: parseInt($(".slider.first1").attr("data-current")),
      connect: [true, false],
      step: 1,
      range: {
        min: (parseInt(min) / 100) * 15,
        max: parseInt((current_price / 100) * 90),
      },
    });
    first.noUiSlider.on("slide", function () {
      $(".calculator__form__field-first1 input").val(
        parseInt(first.noUiSlider.get()).toLocaleString()
      );
      calcValues();
    });
    noUiSlider.create(price, {
      start: parseInt($(".slider.price").attr("data-current")),
      connect: [true, false],
      step: 1,
      range: {
        min: parseInt(min),
        max: parseInt(max),
      },
    });
    price.noUiSlider.on("slide", function () {
      $(".calculator__form__field-price input").val(
        parseInt(price.noUiSlider.get()).toLocaleString()
      );
      console.log(parseInt(price.noUiSlider.get()).toLocaleString());
      console.log(parseInt(first.noUiSlider.get()).toLocaleString());
      calcValues();
      let cur_update_min = parseInt(
        (parseInt(
          $(".calculator__form__field-price input").val().replace(/\s/g, "")
        ) /
          100) *
          15
      );
      first.noUiSlider.set(cur_update_min);
      $(".input_first_min").val(cur_update_min);
      first.noUiSlider.updateOptions({
        range: {
          min: cur_update_min,
          max: parseInt(
            (parseInt(
              $(".calculator__form__field-price input").val().replace(/\s/g, "")
            ) /
              100) *
              90
          ),
        },
      });
    });
    noUiSlider.create(years, {
      start: parseInt($(".slider.years").attr("data-current")),
      connect: [true, false],
      step: 1,
      range: {
        min: parseInt($(".slider.years").attr("data-min")),
        max: parseInt($(".slider.years").attr("data-max")),
      },
    });
    years.noUiSlider.on("slide", function () {
      $(".calculator__form__field-years input").val(
        parseInt(years.noUiSlider.get()).toLocaleString()
      );
      calcValues();
    });
    calcValues();
  }
  if (document.querySelector(".slider.price") != null) {
    calculator();
  }

  function projectGallery() {
    if (window.outerWidth > 970) {
      var thumbs = new Swiper(".project__preview__inner", {
        spaceBetween: 10,
        slidesPerView: 5,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        centeredSlides: true,
        loop: true,
        lazy: true,
        lazy: {
          loadPrevNext: true,
        },
        navigation: {
          nextEl: ".project__preview__control_next",
          prevEl: ".project__preview__control_prev",
        },
      });
    }

    var text = new Swiper(".project__content__inner", {
      allowTouchMove: false,
      speed: 600,
      touchRation: 0,
      spaceBetween: 70,
      slidesPerView: 1,
      navigation: {
        nextEl: ".project__preview__control_next",
        prevEl: ".project__preview__control_prev",
      },
      pagination: {
        el: ".project__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
        clickable: true,
      },
      loop: true,
      lazy: {
        loadPrevNext: true,
      },
    });
    let counter = 0;
    var images = new Swiper(".project__slider__inner", {
      allowTouchMove: true,
      speed: 600,
      touchRation: 0,
      spaceBetween: 0,
      slidesPerView: 1,
      navigation: {
        nextEl: ".project__preview__control_next",
        prevEl: ".project__preview__control_prev",
      },
      pagination: {
        el: ".project__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
        clickable: true,
      },
      breakpoints: {
        970: {
          allowTouchMove: false,
          thumbs: {
            swiper: thumbs,
          },
        },
      },
      loop: true,
      on: {
        slidePrevTransitionStart: function () {
          text.slidePrev();
        },
        slideNextTransitionStart: function () {
          if (counter == 0) {
            counter++;
          } else {
            text.slideNext();
          }
        },
      },
      lazy: {
        loadPrevNext: true,
      },
      thumbs: {
        swiper: thumbs,
      },
    });
  }
  projectGallery();

  var flats_thumbs, flats_images;
  function flatsGallery() {
    if (window.outerWidth > 970) {
      flats_thumbs = new Swiper(
        ".flats__tabs__item.active .flats__preview__inner",
        {
          spaceBetween: 25,
          speed: 600,
          slidesPerView: "auto",
          slidesPerView: 3,
          //centeredSlides: true,
          //freeMode: true,
          //watchSlidesVisibility: true,
          //watchSlidesProgress: true,
          loop: false,
          lazy: true,
          navigation: {
            nextEl: ".flats__tabs__item.active .flats__preview__control_next",
            prevEl: ".flats__tabs__item.active .flats__preview__control_prev",
          },
        }
      );
    }
    flats_images = new Swiper(
      ".flats__tabs__item.active .flats__slider__inner",
      {
        allowTouchMove: false,
        speed: 600,
        touchRation: 0.2,
        spaceBetween: 0,
        slidesPerView: 1,
        loop: false,
        navigation: {
          nextEl: ".flats__tabs__item.active .flats__preview__control_next",
          prevEl: ".flats__tabs__item.active .flats__preview__control_prev",
        },
        lazy: {
          loadPrevNext: true,
        },
        thumbs: {
          swiper: flats_thumbs,
        },
      }
    );
  }
  flatsGallery();
  function flatsTabs() {
    $(".flats__tabs__menu").on(
      "click",
      ".flats__tabs__menu__item:not(.active)",
      function () {
        $(this).addClass("active").siblings().removeClass("active");
        $(".flats__tabs__inner")
          .find(".flats__tabs__item")
          .removeClass("active")
          .eq($(this).index())
          .addClass("active");
        if (window.outerWidth > 970) {
          flats_thumbs.destroy();
        }
        flats_images.destroy();
        flatsGallery();

        return false;
      }
    );
    $(".flats__slider__item__tabs").on(
      "click",
      ".flats__slider__item__tabs__item:not(.active):not(.floor)",
      function () {
        $(this).addClass("active").siblings().removeClass("active");
        $(this)
          .parents(".flats__slider__item")
          .find(".flats__slider__item__image a")
          .fadeOut()
          .eq($(this).index())
          .fadeIn();
        return false;
      }
    );
  }
  flatsTabs();
  function mainDocs() {
    var mySwiper = new Swiper(".main__docs__slider", {
      //allowTouchMove: false,
      //touchRation: 0,
      slidesPerView: 4,
      spaceBetween: 28,
      navigation: {
        nextEl: ".main__docs__slider__control",
        //prevEl: '.first__slider__control:nth-child(1)',
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      loop: false,
    });
  }
  mainDocs();
  function partners() {
    var mySwiper = new Swiper(".partners__slider__inner", {
      //allowTouchMove: false,
      //touchRation: 0,
      slidesPerView: 2,
      spaceBetween: 30,
      navigation: {
        nextEl: ".partners__slider__control:nth-child(2)",
        prevEl: ".partners__slider__control:nth-child(1)",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      loop: true,
      pagination: {
        el: ".partners__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }
  partners();
  function portfolio() {
    var mySwiper = new Swiper(".portfolio__slider__inner", {
      //allowTouchMove: false,
      //touchRation: 0,
      slidesPerView: 2,
      spaceBetween: 30,
      navigation: {
        nextEl: ".portfolio__slider__control:nth-child(2)",
        prevEl: ".portfolio__slider__control:nth-child(1)",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      },
      loop: true,
      pagination: {
        el: ".portfolio__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }
  portfolio();
  function news() {
    var mySwiper = new Swiper(".news__slider__inner", {
      //allowTouchMove: false,
      //touchRation: 0,
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: ".news__slider__control:nth-child(2)",
        prevEl: ".news__slider__control:nth-child(1)",
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 1,
        },
      },
      loop: true,
      pagination: {
        el: ".news__slider__dots",
        bulletClass: "dot",
        bulletActiveClass: "active",
      },
    });
  }
  news();
  // if (document.getElementById('map')) {
  // 	ymaps.ready(function () {
  // 		setMap();
  // 	});
  // }

  if (document.querySelector("#map")) {
    const animationObserver = lozad("#map", {
      load: (el) => {
        let myMap,
          myPlacemark,
          myPlacemark1,
          myPlacemark2,
          myPlacemark3,
          myPlacemark4,
          myPlacemark5,
          myPlacemark6,
          myPlacemark7,
          myPlacemark8,
          myPlacemark9,
          myPlacemark10,
          myPlacemark11,
          myPlacemark12,
          myCircle;

        ymaps
          .load(
            "https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=88c23e34-0064-4be7-959c-afb286f5d104"
          )
          .then((maps) => {
            myMap = new maps.Map("map", {
              center: [53.219484, 44.944986],
              zoom: window.outerWidth > 700 ? 15 : 14,
              controls: [],
            });
            myPlacemark = new maps.Placemark(
              [53.219484, 44.944986],
              {
                hintContent: "ЖК «GRAND PARK»",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_main.png",
                iconImageSize: [72, 72],
                iconImageOffset: [-36, -72],
              }
            );
            let myPlacemark1 = new maps.Placemark(
              [53.219543, 44.947295],
              {
                hintContent: "Офис продаж",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_blue.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark2 = new maps.Placemark(
              [53.220508, 44.95167],
              {
                hintContent: "ТРЦ «Коллаж»",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark3 = new maps.Placemark(
              [53.220287, 44.946226],
              {
                hintContent: "Теннисный корт",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark4 = new maps.Placemark(
              [53.222323, 44.946593],
              {
                hintContent: "Стадион «Сатурн»",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark5 = new maps.Placemark(
              [53.222487, 44.939659],
              {
                hintContent: "Шестая библиотека",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark6 = new maps.Placemark(
              [53.218001, 44.936219],
              {
                hintContent: "Школа №63",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark7 = new maps.Placemark(
              [53.218244, 44.939911],
              {
                hintContent: "Детский сад",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark8 = new maps.Placemark(
              [53.218848, 44.942642],
              {
                hintContent: "Фитнес центр ",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark9 = new maps.Placemark(
              [53.216998, 44.949092],
              {
                hintContent: "Продуктовый гипермаркет «Лента»",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark10 = new maps.Placemark(
              [53.217699, 44.945957],
              {
                hintContent: "Центр медицинской профилактики ",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark11 = new maps.Placemark(
              [53.222458, 44.950809],
              {
                hintContent: "Строительный гипермаркет «Леруа Мерлен»",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myPlacemark12 = new maps.Placemark(
              [53.222913, 44.94849],
              {
                hintContent: "Спортивный магазин «Декатлон» ",
              },
              {
                iconLayout: "default#image",
                iconImageHref:
                  "/wp-content/themes/park_mn/vendor/dist/img/marker_green.png",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -32],
              }
            );
            myCircle = new maps.Circle(
              [[53.219484, 44.944986], 650],
              {},
              {
                fillOpacity: 0.3,
                fillColor: "#6582DB",
                strokeColor: "#5199FF",
              }
            );

            myMap.geoObjects.add(myPlacemark);
            myMap.geoObjects.add(myPlacemark1);
            myMap.geoObjects.add(myPlacemark2);
            myMap.geoObjects.add(myPlacemark3);
            myMap.geoObjects.add(myPlacemark4);
            myMap.geoObjects.add(myPlacemark5);
            myMap.geoObjects.add(myPlacemark6);
            myMap.geoObjects.add(myPlacemark7);
            myMap.geoObjects.add(myPlacemark8);
            myMap.geoObjects.add(myPlacemark9);
            myMap.geoObjects.add(myPlacemark10);
            myMap.geoObjects.add(myPlacemark11);
            myMap.geoObjects.add(myPlacemark12);
            myMap.geoObjects.add(myCircle);
          });
      },
    });
    animationObserver.observe();
  }

  let scrollToTop = () => {
    $(".toTop").click(() => {
      $("html, body").animate({ scrollTop: 0 }, 300);
      return false;
    });
  };
  scrollToTop();
  function focusInput() {
    $("input").focus(function () {
      $(this).parent().addClass("active");
    });
    $("input").blur(function () {
      if ($(this).val().length < 1) {
        $(this).parent().removeClass("active");
      }
    });
  }
  focusInput();
  $.validator.addMethod(
    "regexp",
    function (value, element, regexp) {
      var re = new RegExp(regexp);
      return this.optional(element) || re.test(value);
    },
    "Некорректно"
  );
  $("form").each(function (index, element) {
    $(element).validate({
      highlight: function (element, errorClass, validClass) {
        $(element).addClass("error");
        if ($(element).attr("name") == "form_confirm") {
          $(element).parent().parent().addClass("warning");
        }
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass("error");
        if ($(element).attr("name") == "form_confirm") {
          $(element).parent().parent().removeClass("warning");
        }
      },
      rules: {
        phone: {
          required: true,
          //regexp: /^\+\d{1}\(\d{3}\)\d{3}-\d{2}-\d{2}$/
        },
        email: {
          regexp:
            /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
        },
        form_confirm: {
          required: true,
        },
      },
      messages: {
        form_phone: {
          required: "Пожалуйста, заполните поле",
          email: "Пожалуйста, введите корректный Email",
        },
      },
      submitHandler: function () {
        var msg = new FormData(element);
        $(".loading_mask").addClass("active");
        mna100(
          "email",
          msg,
          function (status) {
            $(".loading_mask").removeClass("active");
            if (status.status == 1) {
              console.log("vkgoal_prepare");
              VK.Goal("lead");
              console.log("vkgoal_complete");
              ym(74710348, "reachGoal", "zayavka");
              gtag("event", "form_lead", {
                event_category: "lead",
                event_action: "zayavka",
              });

              if (index == 9) {
                var _rutarget = window._rutarget || [];
                _rutarget.push({ event: "thankYou", conv_id: "mortgage" });
                console.log("Форма ипотеки");
              } else {
                var _rutarget = window._rutarget || [];
                _rutarget.push({ event: "thankYou", conv_id: "form" });
                console.log("Форма заказа звонка");
              }

              showThanks();
            } else {
              alertify.notify(status.message, "warning", 5);
            }
          },
          function (error) {
            $(".loading_mask").removeClass("active");
          }
        );
        // $.ajax({
        // 	type: 'POST',
        // 	url: '/wp-content/themes/par1k_mn/form.php',
        // 	data: msg,
        // 	success: function (data) {
        // 		$.fancybox.close();
        // 		$(element).find('input').parent().removeClass('active');
        // 		$(element).find('input[type=text]').val('');
        // 		$.fancybox.open($('#modal_thanks'),{
        // 			modal: true,
        // 			hideScrollbar: true,
        // 			smallBtn: false
        // 		} );
        // 		try {
        // 			gtag('event', 'site_lead', { 'event_category': 'lead', 'event_action': 'zayavka', });
        // 			ym(74710348,'reachGoal','zayavka');
        // 		} catch (e) {
        //
        // 		}
        // 	},
        // 	error: function (xhr, str) {
        // 	}
        // });
      },
      success: function (label) {},
    });
  });
  function showThanks(e) {
    console.log(e);
    $.fancybox.close();
    $("form").find("input").parent().removeClass("active");
    $("form").find("input[type=text]").val("");
    $.fancybox.open($("#modal_thanks"), {
      modal: false,
      hideScrollbar: true,
      //smallBtn: false,
      clickOutside: "close",
    });
    try {
      gtag("event", "site_lead", {
        event_category: "lead",
        event_action: "zayavka",
      });
      ym(74710348, "reachGoal", "zayavka");
    } catch (e) {}
    setTimeout(function () {
      $.fancybox.close();
    }, 3000);
  }
  // $('form').on('submit', function(){
  // 	let data = new FormData($(this));
  // 	console.log(data);
  // 	//mna100('email', data, showThanks, error)
  // })
  const observer = lozad(".lozad");
  window.observer = observer;
  window.observer.observe();
  window.onload = function () {
    //outNum(100, 500);
  };
  $(window).on("scroll", function () {
    if (window.innerWidth <= 768 && window.pageYOffset > 20) {
      $(".footer__fixed").fadeIn();
    }
  });
});
